/* eslint-disable @typescript-eslint/no-explicit-any */
import { API_STATUS_CODE, AddToCartType } from '@/constants'
import { useLocalStorage } from '@/hooks'
import {
  type CartItemType,
  type GiftItems,
  type GuestInfo,
  type Order,
  type OrderService,
  type PaymentMethod,
  type Product,
  type VoucherItem,
} from '@/types'
import { api } from '@/utils'
import { getTranslate } from '@/utils/api-interceptors'
import * as pixel from '@/utils/fpixel'
import { delay, scrollToTop } from '@/utils/next-util'
import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'
import constate from 'constate'
import { getCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from './AuthContainer'
import { useNotification } from './NotificationContainer'
const CartContainer = () => {
  const [cartLoading, setCartLoading] = useState(false)

  const [productLoading, setProductLoading] = useState('')
  const [orderInfo, setOrderInfo] = useState<Order | null>(null)
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>()
  const { showProgressBar, hideProgressBar } = useNotification()
  const [statusCode, setStatusCode] = useState('')
  const [listCoupons, setListCoupons] = useState<VoucherItem[]>()
  const [cartCoupons, setCartCoupons] = useState<VoucherItem[]>()
  const {
    getGuessTokenCart,
    fetchCart,
    setCart,
    setErrorAuth,
    voucherApply,
    token,
  } = useAuth()
  const [couponApply, setCouponApply] = useState<string>('')
  const [guestInfo, setGuestInfo] = useLocalStorage<GuestInfo | null>(
    'guestInfo',
    null
  )
  const [isPreOrder, setIsPreOrder] = useState<boolean>(false)
  const [isInstallment, setIsInstallment] = useState<boolean>(false)
  const router = useRouter()
  const currency = (lang: string) => {
    switch (lang) {
      case 'vi':
        return 'VND'
      case 'en':
        return 'USD'
      default:
        return 'VND'
    }
  }
  useEffect(() => {
    if (router?.query?.type == 'preOrder') {
      setIsPreOrder(true)
    }
  }, [router])
  const { mutate: addToCards } = useMutation(
    'addToCards',
    async (values: { product: any; quantity: number }) => {
      const token = getGuessTokenCart()
      showProgressBar()
      return api(`api/shopping-cart`, {
        method: 'POST',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
        data: {
          productId: values?.product?.productId,
          quantity: values?.quantity,
          serviceIds: values?.product?.serviceIds,
          giftItems: values?.product?.giftItems,
          shippingMethod: values.product.shippingMethod,
          selectedReceiveAtWarehouse:
            values?.product?.selectedReceiveAtWarehouse,
        },
        ...(token ? { headers: { uuid: token } } : {}),
      }).then(res => {
        if (res instanceof Error) {
          throw new Error((res as any)?.statusCode)
        }
        return res
      })
    }
  )
  const { mutate: addToPreOrder } = useMutation(
    'addPreOrder',
    async (values: { product: any; quantity: number }) => {
      const token = getGuessTokenCart()
      showProgressBar()
      return api(`api/shopping-cart`, {
        method: 'POST',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
        data: {
          productId: values?.product?.productId,
          isPreOrder: true,
          quantity: values?.quantity,
          serviceIds: values?.product?.serviceIds,
          giftItems: values?.product?.giftItems,
          selectedReceiveAtWarehouse:
            values?.product?.selectedReceiveAtWarehouse,
        },
        ...(token ? { headers: { uuid: token } } : {}),
      }).then(res => {
        if (res instanceof Error) {
          throw new Error((res as any)?.statusCode)
        }
        return res
      })
    }
  )
  const { mutate: addBundleToCarts } = useMutation(
    'addBundleToCarts',
    async (bundleProducts: any) => {
      // const token = getGuessTokenCart()
      showProgressBar()
      return api(`api/shopping-carts`, {
        method: 'POST',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
        data: bundleProducts, // Sử dụng trực tiếp bundleProducts thay vì { bundleProducts }
      }).then(res => {
        if (res instanceof Error) {
          throw new Error((res as any)?.statusCode)
        }
        return res
      })
    }
  )
  const { mutate: addToInstallment } = useMutation(
    'addInstallment',
    async (values: { product: any; quantity: number; productIds: string }) => {
      const token = getGuessTokenCart()
      showProgressBar()
      const params = values?.productIds
        ? 'productIds=' + values?.productIds + ':1'
        : ''
      return api(`api/shopping-cart?${params}`, {
        method: 'POST',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
        data: {
          isInstallment: true,
          productId: values?.product?.productId,
          quantity: values?.quantity,
          serviceIds: values?.product?.serviceIds,
          giftItems: values?.product?.giftItems,
          selectedReceiveAtWarehouse:
            values?.product?.selectedReceiveAtWarehouse,
        },
        ...(token ? { headers: { uuid: token } } : {}),
      }).then(res => {
        if (res instanceof Error) {
          throw new Error((res as any)?.statusCode)
        }
        return res
      })
    }
  )

  const getOrderDetail = async (orderId: string) => {
    try {
      const res = await api(`api/order/${orderId}/detail`, {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      })
      if (res?.data) {
        setOrderInfo(res.data)
      } else {
        // Xử lý trường hợp không nhận được dữ liệu từ API
      }
    } catch (error) {
      // Xử lý lỗi khi gọi API
      console.error('Error fetching order detail:', error)
    }
  }

  const cancelOrder = async (order?: Order | null) => {
    if (!order) {
      return
    }
    const res = await api(`api/order/${order._id}`, {
      method: 'delete',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
    }).then(e => {
      setCartLoading(true)
      return e
    })
    if (res instanceof Error) {
      console.log('error', res)
    }
    toast.success('Hủy đơn hàng thành công', {
      position: 'bottom-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })

    await api(
      `api/order/${router.query.orderNumberId || router.query.orderId}/detail`,
      {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      }
    ).then(e => {
      setOrderInfo(e.data)
    })
    fetchCart()
    scrollToTop()
  }

  const confirmPayment = async (order?: Order | null) => {
    if (!order) {
      return
    }
  }

  const choosePaymentMethod = async (
    order?: Order | null,
    paymentMethod?: PaymentMethod | null,
    reloadCart?: boolean
  ) => {
    if (!order) {
      return
    }
    setCartLoading(true)
    if (paymentMethod && paymentMethod.isOnline) {
      const res = await api(`api/payment/link`, {
        method: 'POST',
        baseURL: process.env.NEXT_PUBLIC_FINANCE_API_URL,
        data: {
          paymentChannel: paymentMethod?.value,
          paymentType: paymentMethod?.paymentType,
          bankType: paymentMethod?.bank_type,
          bankCode: '',
          orderCode: order?.code,
          amount: order?.totalAmount,
          currency: 'VND',
          redirectUrl:
            window.location.origin +
            '/checkout/confirm?orderNumberId=' +
            order?._id,
          transactionPurpose: '',
        },
      })

      setStatusCode(res?.statusCode)
      if (res && !(res instanceof Error)) {
        if (res.data) {
          return router.push(res?.data)
        } else {
          setCartLoading(false)
          throw new Error(res?.statusCode)
        }
      }
    } else {
      await api(`api/order/${order._id}`, {
        method: 'put',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
        data: {
          payment: {
            method: paymentMethod?.value,
          },
        },
      })
    }
    setCartLoading(false)
    if (reloadCart) fetchCart()
    await delay(100)
    scrollToTop()
  }

  useEffect(() => {
    if (voucherApply.length > 0) {
      setCouponApply(voucherApply)
    }
    if (!token) {
      setCouponApply('')
    }
  }, [voucherApply, token])

  const addToCart = async (product: any, type: string, anchor?: string) => {
    const cartItemData = {
      code: product.code,
      name: product.name,
      price: product.price,
      slugs: product.slugs,
      productId: product._id,
      quantity: 1,
      finalPrice: product.finalPrice,
      serviceIds: product?.addService,
      giftItems: product?.addGift,
      shippingMethod: product?.shippingMethod,
      selectedReceiveAtWarehouse: product?.selectedReceiveAtWarehouse,
    }
    setCartLoading(true)
    setProductLoading(product?._id)
    if (type === AddToCartType.PRE_ORDER) {
      await addToPreOrder(
        {
          product: cartItemData,
          quantity: 1,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            pixel.fbqEvent('AddToCart', {
              content_name: getTranslate(product.name),
              content_category: getTranslate(product.cat?.name),
              content_ids: product.code,
              content_type: 'product',
              value: product.finalPrice,
              currency: 'VND',
            })
            sendGTMEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            sendGAEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            setCartLoading(false)
            setProductLoading('')
            hideProgressBar()
            router.push(
              '/checkout/cart?type=preOrder&productIds=' + product._id
            )
          },
          onError: err => {
            fetchCart()
            setCartLoading(false)
            setProductLoading('')
            if (err && err instanceof Error) {
              if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
                router.push(
                  '/checkout/cart?type=preOrder&productIds=' + product._id
                )
              }
            }
            hideProgressBar()
          },
        }
      )
    }
    if (type === AddToCartType.INSTALLMENT) {
      await addToInstallment(
        {
          product: cartItemData,
          quantity: 1,
          productIds: cartItemData.productId,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            pixel.fbqEvent('AddToCart', {
              content_name: getTranslate(product.name),
              content_category: getTranslate(product.cat?.name),
              content_ids: product.code,
              content_type: 'product',
              value: product.finalPrice,
              currency: 'VND',
            })
            sendGTMEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            sendGAEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            setCartLoading(false)
            setProductLoading('')
            hideProgressBar()
            router.push(
              '/checkout/cart?type=installment&productIds=' + product._id
            )
          },
          onError: err => {
            fetchCart()
            setCartLoading(false)
            setProductLoading('')
            if (err && err instanceof Error) {
              if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
                router.push(
                  '/checkout/cart?type=installment&productIds=' + product._id
                )
              }
            }
            hideProgressBar()
          },
        }
      )
    }
    if (
      type !== AddToCartType.PRE_ORDER &&
      type !== AddToCartType.INSTALLMENT
    ) {
      await addToCards(
        {
          product: cartItemData,
          quantity: 1,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            pixel.fbqEvent('AddToCart', {
              content_name: getTranslate(product?.name),
              content_category: getTranslate(product?.cat?.name),
              content_ids: product?.code,
              content_type: 'product',
              value: product?.finalPrice,
              currency: currency(getCookie('language') as string),
            })
            sendGTMEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            sendGAEvent({
              event: 'add_to_cart',
              value: product.finalPrice,
              currency: 'VND',
              item: [
                {
                  item_id: product.code,
                  item_name: getTranslate(product.name),
                  affiliation: 'CPR',
                  discount: product.price - product.finalPrice,
                  item_category: product?.cat
                    ? getTranslate(product.cat.name)
                    : '',
                  price: product.price,
                  quantity: 1,
                },
              ],
            })
            setCartLoading(false)
            setProductLoading('')
            hideProgressBar()
            if (type === AddToCartType.BUY_NOW) {
              console.log('anchor', anchor)
              router.push(`/checkout/cart`)
            }
          },
          onError: err => {
            fetchCart()
            console.log('err', err)
            hideProgressBar()
            setCartLoading(false)
            setProductLoading('')
            if (err && err instanceof Error) {
              if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
                router.push('/checkout/cart')
              }
            }
          },
        }
      )
    }
  }
  const addBundleToCart = async (data: Product[], type: string) => {
    await addBundleToCarts(
      {
        data,
      },
      {
        onSuccess: async res => {
          setCart({
            ...res.data,
            quantity: res?.data?.products?.reduce(
              (acc: number, e: CartItemType) => acc + e?.quantity,
              0
            ),
          })
          setCartLoading(false)
          hideProgressBar()
          if (type === AddToCartType.BUY_NOW) {
            router.push('/checkout/cart')
          }
        },
        onError: err => {
          fetchCart()
          console.log('err', err)
          hideProgressBar()
          setCartLoading(false)
          if (err && err instanceof Error) {
            if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
              router.push('/checkout/cart')
            }
          }
        },
      }
    )
  }
  const updateOrderInfo = () => true

  const changeQuantityItem = (
    product: CartItemType,
    quantity: number,
    gift: GiftItems[],
    service: OrderService[],
    shippingMethod: string,
    selectedReceiveAtWarehouse: boolean,
    type: string
  ) => {
    setCartLoading(true)
    const productCartData = {
      ...product,
      serviceIds: service,
      giftItems: gift,
      shippingMethod,
      selectedReceiveAtWarehouse: selectedReceiveAtWarehouse,
    }
    if (type === AddToCartType.PRE_ORDER) {
      addToPreOrder(
        {
          product: productCartData,
          quantity: quantity,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res.data.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            fetchCart({
              wardCode: res.wardCode,
              shippingMethod: productCartData.shippingMethod,
              productIds: productCartData.productId,
              ...(couponApply ? { coupon: couponApply } : {}),
              ...(isPreOrder ? { isPreOrder: true } : {}),
              action: 'cart container 1',
            }).catch(err => {
              setErrorAuth({
                status: true,
                message: err.message,
                key: 'update qty false',
              })
            })
            hideProgressBar()
            getCartCoupons()
            setCartLoading(false)
          },
          onError: err => {
            fetchCart({ isPreOrder: true })
            setCartLoading(false)
            if (err && err instanceof Error) {
              if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
                router.push('/checkout/cart')
              }
            }
            hideProgressBar()
          },
        }
      )
    } else {
      addToCards(
        {
          product: productCartData,
          quantity: quantity,
        },
        {
          onSuccess: res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            fetchCart({
              wardCode: res.wardCode,
              ...(couponApply ? { coupon: couponApply } : {}),
              ...(isPreOrder ? { isPreOrder: true } : {}),
              action: 'cart container 2',
            }).catch(err => {
              setErrorAuth({
                status: true,
                message: err.message,
                key: 'update qty false',
              })
            })
            getCartCoupons()
            hideProgressBar()
            setCartLoading(false)
          },
          onError: err => {
            fetchCart()
            console.log('err', err)
            hideProgressBar()
            setCartLoading(false)
            if (err && err instanceof Error) {
              if (err?.message == API_STATUS_CODE.PRODUCT_LIMIT_SALE) {
                router.push('/checkout/cart')
              }
            }
          },
        }
      )
    }
  }
  const getListCoupons = async () => {
    const res = await api(`api/profile/coupons`, {
      method: 'get',
      baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    })
    return setListCoupons(res.data)
  }
  const getCartCoupons = async () => {
    const res = await api(`api/shopping-cart/coupons`, {
      method: 'get',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
    })
    return setCartCoupons(res.data)
  }
  useEffect(() => {
    updateOrderInfo()
  }, [])

  const updateGiftAndService = (
    item: CartItemType,
    giftItems: GiftItems[],
    services: OrderService[],
    shippingMethod?: string,
    selectedReceiveAtWarehouse?: boolean,
    type?: string
  ) => {
    setCartLoading(true)
    const cartItemData = {
      code: item.code,
      name: item.name,
      price: item.price,
      shippingMethod: shippingMethod,
      slugs: item.slugs,
      productId: item.productId,
      quantity: item.quantity,
      finalPrice: item.finalPrice,
      giftItems: giftItems,
      serviceIds: services,
      selectedReceiveAtWarehouse: selectedReceiveAtWarehouse,
    }
    if (type === AddToCartType.PRE_ORDER) {
      addToPreOrder(
        {
          product: cartItemData as any,
          quantity: 0,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            fetchCart({
              wardCode: res.wardCode,
              shippingMethod: cartItemData.shippingMethod,
              productIds: cartItemData.productId,
              ...(isPreOrder ? { isPreOrder: true } : {}),
              ...(couponApply.length ? { coupon: couponApply } : {}),
              action: 'cart container 3',
            }).catch(err => {
              setErrorAuth({
                status: true,
                message: err.message,
                key: 'update gift',
              })
            })
            hideProgressBar()
            setCartLoading(false)
          },
        }
      )
    }
    if (type === AddToCartType.INSTALLMENT) {
      addToInstallment(
        {
          product: cartItemData as any,
          quantity: 0,
          productIds: cartItemData.productId,
        },
        {
          onSuccess: async res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            fetchCart({
              wardCode: res.wardCode,
              shippingMethod: cartItemData.shippingMethod,
              productIds: cartItemData.productId + ':1',
              ...(isPreOrder ? { isPreOrder: true } : {}),
              ...(couponApply ? { coupon: couponApply } : {}),
              action: 'cart container 4',
            }).catch(err => {
              setErrorAuth({
                status: true,
                message: err.message,
                key: 'update gift',
              })
            })
            hideProgressBar()
            setCartLoading(false)
          },
        }
      )
    }
    if (
      type !== AddToCartType.PRE_ORDER &&
      type !== AddToCartType.INSTALLMENT
    ) {
      addToCards(
        {
          product: cartItemData as any,
          quantity: 0,
        },
        {
          onSuccess: res => {
            setCart({
              ...res.data,
              quantity: res?.data?.products?.reduce(
                (acc: number, e: CartItemType) => acc + e?.quantity,
                0
              ),
            })
            fetchCart({
              wardCode: res.wardCode,
              ...(couponApply ? { coupon: couponApply } : {}),
              ...(isPreOrder ? { isPreOrder: true } : {}),
              shippingMethod: cartItemData.shippingMethod,
              action: 'cart container 5',
              // shippingMethod,
            }).catch(err => {
              setErrorAuth({
                status: true,
                message: err.message,
                key: 'update gift',
              })
            })
            getCartCoupons()
            hideProgressBar()
            setCartLoading(false)
          },
        }
      )
    }
  }

  const chooseDestination = async (wardCode: string, productId: string) => {
    if (!wardCode || !productId) {
      return
    }
    setCartLoading(true)
    const res = await api(`api/shipment/shipping-rate`, {
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      data: {
        productId,
        destinationCode: wardCode,
      },
    }).catch(() => {
      setCartLoading(false)
    })
    await delay(200)
    setCartLoading(false)
    return res.data.shippingRate
  }

  useEffect(() => {
    updateOrderInfo()
  }, [])

  useEffect(() => {
    if (router && router.query.orderNumberId) {
      api(`api/order/${router.query.orderNumberId}/detail`, {
        method: 'get',
        baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      })
        .then(e => {
          if (e instanceof Error) {
            return router.push('/search-order')
          }
          setOrderInfo(e.data)
        })
        .catch(e => {
          console.log(e)
          return router.push('/search-order')
        })
    }
  }, [router])

  const sendContact = async (values: {
    name: string
    phone: string
    productIds: string[]
  }) => {
    if (!values) {
      return
    }
    setCartLoading(true)
    const res = await api(`api/consultant/`, {
      method: 'post',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      data: values,
    }).then(e => {
      return e
    })
    if (res instanceof Error) {
      console.log('error', res)
    }
    setCartLoading(false)
    return res
  }

  return {
    addToCart,
    changeQuantityItem,
    timer,
    cartLoading,
    getGuessTokenCart,
    setTimer,
    getOrderDetail,
    cancelOrder,
    confirmPayment,
    choosePaymentMethod,
    orderInfo,
    updateGiftAndService,
    chooseDestination,
    setGuestInfo,
    guestInfo,
    statusCode,
    getListCoupons,
    listCoupons,
    cartCoupons,
    setCartCoupons,
    getCartCoupons,
    sendContact,
    couponApply,
    setCouponApply,
    isPreOrder,
    setIsPreOrder,
    isInstallment,
    setIsInstallment,
    addBundleToCart,
    productLoading,
  }
}
export const [CartProvider, useCart] = constate(CartContainer)
